// This file contains GLOBAL overrides for the currently active Material Design theme.
//
// As per Angular Material's theming guide-lines the outside styling of Material components is not supported
// and this should be handled as an escape-hatch instead of a default go-to method.

// This is the default style for all opened dialog containers.
// The app dialog class is added to opened dialogs via the open method `panelClass` property.
.app-dialog {
  padding: 0;
}

// The MDC version of input contains a placeholder for error messages but this container has no bottom margin.
// We always want to space our error message container from the next element.
.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 6px;
}

// The density option seems to mess up text alignment in the input.
// This is mostly related to changed text alignment to baseline instead of center.
// TODO: We need to sometimes in future check if this is needed in latest Material release
.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
  line-height: normal;
}

// There is no row separator line between the last line in the table and paginator.
// This leads to weird looking tables when the table lines are not colored, so we
// globally override this to simulate the last row separator line when there is paginator under the table.
mat-paginator {
  border-top: 1px solid rgb(220, 220, 220)
}


.mat-mdc-paginator {
  // The Material table paginator has it's text color hard-coded and the default black value used
  // draws too much attention so we fallback using a lighter color.
  color: #0000008a;

  // The default pagination container takes up too much vertical space since they switch to MDC components.
  // To remedy this we set the default height to auto to allow the browser to select the minimum height based on content.
  & .mat-mdc-paginator-container {
    min-height: auto;
  }
}

mat-hint {
  color: #0009;
}

.mat-mdc-header-cell.mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 500;
  color: #0000008a;
}

.month-picker .mat-calendar-period-button {
  pointer-events: none;
}

.month-picker .mat-calendar-arrow {
  display: none;
}
